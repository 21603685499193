@import "theorem-lib/src/index.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Sidebar */
@layer components {
    .bg-sidebar {
        @apply bg-grey-800
    }

    .top-acronym-container {
        @apply border-white
    }

    .top-acronym {
        @apply text-white
    }

    .menu-item {
        @apply text-white;
    }

    .menu-item-active {
        @apply text-grey-800;
    }
}
